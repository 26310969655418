<template>
  <div class="register">
    <loginHeader></loginHeader>
    <div class="register-body">
      <div class="register-content" :class="clientWidth > 750 ? 'flex0' : ''">
        <div class="register-detail">
          <h1>欢迎注册</h1>
          <el-form :model="registerForm" :rules="rules" ref="registerForm" class="registerForm">
            <el-form-item prop="surname">
              <el-input v-model.trim="registerForm.surname" placeholder="请输入姓氏"></el-input>
            </el-form-item>
            <el-form-item prop="username">
              <el-input v-model.trim="registerForm.username" placeholder="请输入名字"></el-input>
            </el-form-item>
            <el-form-item prop="name">
              <el-input v-model.trim="registerForm.name" placeholder="请输入昵称"></el-input>
            </el-form-item>
            <el-form-item prop="pwd" class="passwordInput">
              <el-input v-model.trim="registerForm.pwd" :type="passwordType" placeholder="请设置您的密码" ref="password"></el-input>
              <span class="show-pwd cur" @click="showPwd">
                <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
              </span>
            </el-form-item>
            <el-form-item prop="phone">
              <el-input v-model.trim="registerForm.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item prop="email" class="form-item email_input way_input">
              <el-input v-model.trim="registerForm.email" placeholder="请输入您的邮箱地址"></el-input>
            </el-form-item>
            <el-form-item prop="way" class="way_input">
              <el-radio-group v-model="registerForm.way" @change="codetypechange">
                <el-radio label="phone">手机号验证</el-radio>
                <el-radio label="email">邮箱验证</el-radio>
              </el-radio-group>
              <!-- <p class="notice">*非中国大陆手机号请使用邮箱验证方式</p> -->
            </el-form-item>
            <el-form-item prop="Code">
              <div class="flex2">
                <el-input v-model.trim="registerForm.Code" placeholder="请输入验证码" style="margin-right: 5px" maxlength="6"></el-input>
                <el-button class="getCode" v-if="codeShow" @click="getPhoneCode">获取验证码</el-button>
                <el-button class="getCode" v-else disabled>{{ count }} 秒后重试</el-button>
              </div>
            </el-form-item>
            <el-form-item prop="partnerType">
              <el-select v-model="registerForm.partnerType" placeholder="请选择类型" @change="typeChange">
                <el-option v-for="item in Types" :key="item.value" :label="item.text" :value="item.value"></el-option>
                <el-option v-for="item in Module.partnerType" :key="item.value" :label="item.text" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <!-- 公司版本1 -->
            <el-form-item v-if="IsCompany" prop="companyId">
              <el-select v-model="registerForm.companyId" popper-class="my-select" :popper-append-to-body="false" style="width: 100%" placeholder="请选择公司">
                <el-option v-for="item in options" :key="item.id" :label="item.partnerName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <!-- IsCompanies -->
            <div style="margin-bottom: 10px" v-if="false">
              <el-checkbox v-model="IsCompanyNull" @change="PartnerChange('registerForm')"></el-checkbox>
              <span> 不在列表中，请勾选后再进行填写</span>
            </div>
            <!-- <el-form-item v-if="!IsCompany" prop="company">
              <el-input v-model.trim="registerForm.company" placeholder="请输入公司名称"></el-input>
            </el-form-item> -->
            <el-form-item v-if="!IsCompany" prop="company">
              <el-select v-model="registerForm.company" placeholder="请输入公司" ref="select" filterable remote reserve-keyword :remote-method="remoteMethod" :loading="loading"  @focus="clear" @hook:mounted="clear" @visible-change="clear">
                <el-option v-for="(item, index) in searchOptions" :key="index" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <!-- 公司版本2 -->
            <!-- <el-form-item prop="companyId">
              <el-select v-model="registerForm.companyId" placeholder="请选择公司" filterable remote reserve-keyword :remote-method="remoteMethod" :loading="loading">
                <el-option v-for="(item, index) in options" :key="index" :value="item"></el-option>
              </el-select>
            </el-form-item> -->
            <!-- 行业版本1 -->
            <!-- <el-form-item v-if="IsIndustry" prop="Industry" multiple key="100001">
              <el-select v-model="IndustryItems" multiple placeholder="请选择行业(可多选)" @change="IndustryItem">
                <el-option v-for="item in Module.industrys" :key="item.value" :label="item.text" :value="item.value"></el-option>
              </el-select>
              <el-cascader
                v-model="valueTest"
                :options="testIndustrys"
                placeholder="请选择行业(可多选)"
                :props="props"
                clearable></el-cascader>
            </el-form-item>
            <el-form-item v-else prop="Industry" key="100002">
              <el-select v-model="registerForm.Industry" placeholder="请选择行业">
                <el-option v-for="item in Module.industrys" :key="item.value" :label="item.text" :value="item.value"></el-option>
              </el-select>
              <el-cascader
                v-model="valueTest"
                :options="testIndustrys"
                placeholder="请选择行业"
                @change="handleIndustry"></el-cascader>
            </el-form-item> -->
            <!-- 行业版本1 -->
            <el-form-item prop="industrySecond" multiple>
              <el-cascader
                v-model="IndustryItems"
                :options="Module.industrys"
                placeholder="请选择行业(可多选)"
                :props="props"
                @change="IndustryItem1"></el-cascader>
            </el-form-item>
            <el-form-item prop="industryOther" v-if="IsIndustryOther">
              <el-input v-model.trim="registerForm.industryOther" placeholder="请输入行业"></el-input>
            </el-form-item>
            <!-- 和职务合并 -->
            <!-- <el-form-item prop="scope">
              <el-select v-model="registerForm.scope" placeholder="请选择职务" filterable>
                <el-option v-for="item in Module.scope" :key="item.value" :label="item.text" :value="item.value * 1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="!IsIndustry" prop="positionId">
              <el-select v-model="registerForm.positionId" placeholder="请选择工作职能" filterable>
                <el-option v-for="item in Module.position" :key="item.value" :label="item.text" :value="item.value * 1"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item prop="scope">
              <el-cascader
                v-model="ScodeItems"
                :options="Module.position"
                placeholder="请选择职务"
                :props="props1"
                @change="ScodeItem1"></el-cascader>
            </el-form-item>
            <el-form-item prop="scopeOther" v-if="IsOther">
              <el-input v-model.trim="registerForm.scopeOther" placeholder="请输入职务"></el-input>
            </el-form-item>
            <!-- <el-form-item>
              <el-input v-model.trim="registerForm.invitationUserCode" placeholder="请输入您的邀请码" maxlength="8"></el-input>
            </el-form-item> -->

            <div v-if="channel !== -1 && channel" class="subscription">
              <el-checkbox v-model="synchronization" class="flex">
                是的，同步注册成为剑盟社区会员
              </el-checkbox>
            </div>
            <div class="subscription">
              <el-checkbox v-model="subscription" class="flex">
                是的，让我及时了解有关 AVEVA 新闻、活动和解决方案以及行业和行业相关材料的最新内容和信息。
                我明白可以随时点击 AVEVA 所有电子邮件底部的取消订阅链接取消订阅。
              </el-checkbox>
            </div>
            <div class="privacy-text">
              <el-checkbox v-model="privacy" class="flex">
                我确认已阅读
                <a href="https://www.aveva.com/en/legal/privacy-policy/" target="_blank" >AVEVA 隐私政策</a>
                并确认我的个人数据的处理详情。
              </el-checkbox>
              <div class="agrees">
                如果您继续使用，即表示同意
                  <a @click="toAgreeMent" href="javascript: void(0)" >
                    《 AVEVA 社区微站用户使用协议(剑盟协议)》
                  </a>
              </div>
            </div>
            <el-form-item>
              <el-button type="info" size="medium" :loading="loading" @click="submitForm('registerForm')" class="pc-register loginBtn" >注 册</el-button>
            </el-form-item>
            <div>
              <span>已有账号？ </span>
              <span class="CancelHome link" @click="goLogin">立即登录</span>
              <span class="CancelHome link" @click="goHome">返回首页</span>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <login-alert></login-alert>
    <Footers></Footers>
  </div>
</template>

<script>
import LoginAlert from "@/components/LoginAlert/index.vue";
import loginHeader from "@/components/loginHeader.vue";

import { getCompany, getCode, setRegister} from "@/api/register.js";
import { GetCompanyList } from "@/api/Home.js";
import { debounce, Login_GetInfoDetails } from "@/utils/common";
import { dataState } from "@/api/all.js";
import { mapState } from 'vuex'
import Footers from '@/components/footer.vue';

export default {
  name: "Register",
  data() {
    return {
      props: { multiple: true, value: 'value', label: 'text', children: 'list' },
      props1: { multiple: false, value: 'value', label: 'text', children: 'list' },
      loading: false,
      
      IndustryItems:'',                    // !行业多选
      ScodeItems: '',                      // !职务单选
      passwordType: 'password',            // !查看密码
      codeShow: true,                      // !获取验证码选中状态
      loading: false,                      // !登录loading
      count: "",                           // !验证码状态
      searchOptions: [],                   // !搜索公司数据
      options: [],                         // !公司数据
      privacy: false,                      // !隐私链接是否选中
      subscription: false,                 // !订阅是否选中
      // ! 是否同步剑盟社区
      synchronization: false,

      Types: [{text: '客户', value: '0'}], // !类型
      registerForm: {
        surname: "",                       // !姓
        username: "",                      // !名
        name: "",                          // !昵称
        pwd: "",                           // !密码
        phone: "",                         // !手机号
        email: "",                         // !邮箱
        partnerType: "",                   // !类型
        company: "",                       // !公司
        companyId: "",                     // !公司Id
        positionId: "",                    // !工作职能
        invitationUserCode: "",            // !邀请码
        codetype: 1,
        Code: "",                          // !验证码
        // Industry: "",                      // !行业
        industry: "",
        industrySecond: "",
        industryOther: "",
        scope: '',                         // !职务
        scopeOther: '',                    // !其他职务
        uuid: this.$route.query.uuid ? this.$route.query.uuid : '', // !微信绑定注册
        type: 1,
        // !邀请人 id
        inviterUserId: '',
        way: 'phone',                      // !验证方式
        e3DInviterUserId: ''
      },
      IsIndustry: false,                   // !行业是否多选
      IsCompany: false,                    // !公司是否为输入
      IsCompanyNull: false,                // !没有公司数据
      IsCompanies: false,                  // !系统集成商时显示
      IsIndustryOther: false,
      IsOther: false,                      // !职务选择其他

      rules: {
        surname: [
          { required: true, message: "请输入姓氏", trigger: "blur" },
          { min: 1, max: 30, message: "长度在1个字符至20个字符之间", trigger: "blur"},
        ],
        username: [
          { required: true, message: "请输入名字", trigger: "blur" },
          { min: 1, max: 30, message: "长度在1个字符至20个字符之间", trigger: "blur"},
        ],
        name: [
          { required: true, message: "请输入昵称", trigger: "blur" },
          { min: 2, max: 30, message: "长度在2个字符至30个字符之间", trigger: "blur"},
        ],
        pwd: [
          { required: true, message: "请设置您的密码", trigger: "blur" },
          { min: 6, max: 16, message: "长度在6个字符至16个字符中间", trigger: "blur"},
        ],
        phone: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
          // {pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,message: "请输入正确的手机号",trigger: "blur"},
        ],
        Code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        email: [
          { required: true, message: "请输入您的邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        partnerType: [{required: true, message: "请选择类型", trigger: "change" }],
        company: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
        companyId: [{ required: true, message: "请选择公司名称", trigger: "change" }],
        industrySecond: [{ required: true, message: "请选择行业", trigger: "change" }],
        industryOther: [{ required: true, message: "请输入行业", trigger: "blur" }],
        positionId: [{ required: true, message: "请选择工作职能", trigger: "change", }],
        scope: [{ required: true, message: "请选择职务", trigger: "change" }],
        scopeOther: [{ required: true, message: "请输入职务", trigger: "blur" }]
      },
    };
  },
  mounted() {
    // !获取公司名称
    getCompany().then((res) => {
      this.options = res.data;
    });
    // 数据统计
    const data ={
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "register",
      "relationId": ""
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid',res.data);
    })
  },
  methods: {
    clear(async) {
      this.$nextTick(() => {
        if (!async) {
          // ios 手机有延迟问题
          setTimeout(() => {
            const { select } = this.$refs
            const input = select.$el.querySelector('.el-input__inner')
            input.removeAttribute('readonly')
          }, 200)
        }
      })
    },
    handleIndustry(val){
      console.log(val);
    },
    codetypechange(val){
      console.log(val)
    },
    toAgreeMent() {
      const { href } = this.$router.resolve({
        name: "AgreeMent",
      });
      window.open(href, "_blank");
    },
    // 搜索公司
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.GetCompanyList(query);
        }, 200);
      } else {

      }
    },
    // 获取公司列表
    GetCompanyList(key) {
      GetCompanyList({keyword: key}).then(res => {
        this.searchOptions = res.data
      })
    },
    // !监听多行业选择
    IndustryItem(){
      this.registerForm.Industry = this.IndustryItems.join(',');
    },
    IndustryItem1(){
      var arr = []
      var arr_children = []
      this.IndustryItems.forEach(item => {
        arr.push(item[0])
        arr_children.push(item[1])
      })

      // 判断选择其他时
      let textArr = []
      this.$store.state.Module.industrys.forEach(item => {
        if(arr.includes(item.value)){
          textArr.push(item.text)
        }
      })
      if(textArr.includes('其他')){
        this.IsIndustryOther = true
      }else{
        this.IsIndustryOther = false
        this.registerForm.industryOther = ""
      }

      this.registerForm.industry = [...new Set(arr)].join(',')
      this.registerForm.industrySecond = arr_children.join(',')
    },
    // 职务选择
    ScodeItem1() {
      this.registerForm.positionId = parseInt(this.ScodeItems[0])
      this.registerForm.scope = parseInt(this.ScodeItems[1])
      var str
      this.$store.state.Module.position.filter(item => {
        item.list.filter(val => {
          if(val.value == this.ScodeItems[1]){
            str = val.text
          }
        })
      })
      if(str == '其他') {
        this.IsOther = true
      } else {
        this.IsOther = false
        this.registerForm.scopeOther = ""
      }
    },
    // !监听类型选择
    typeChange() {
      // this.registerForm.Industry = '';
      this.IndustryItems = '';
      this.industryOther = '';
      this.IsIndustryOther = false
      this.registerForm.positionId = '';
      this.registerForm.company = '';
      this.registerForm.companyId = '';

      if(this.registerForm.partnerType == "10481"){
        this.IsCompany = true;
        getCompany({'Id': '10481'}).then((res) => {
          this.options = res.data;
        });
      }else {
        this.IsCompany = false;
        // getCompany().then((res) => {
        //   this.options = res.data;
        // });
      }

      if(this.registerForm.partnerType == "10482"){
        this.IsIndustry = true;
        this.IsCompany = true;
        this.IsCompanies = true;
        getCompany({'Id': '10482'}).then((res) => {
          this.options = res.data;
        });
      }else {
        this.IsCompanies = false;
        this.IsCompanyNull = false;
      }

      if(this.registerForm.partnerType != '0'){
        this.IsIndustry = true;
      }else{
        this.IsIndustry = false;
      }
    },
    // !系统集成商时，公司填写
    PartnerChange(registerForm){
      this.registerForm.company = '';
      this.registerForm.companyId = '';
      if(this.IsCompanyNull){
        this.IsCompany = false;
      }else {
        this.IsCompany = true;
      }
    },
    // !注册
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.privacy) {
            this.loading = true;
            this.registerForm.inviterUserId = this.inviterId
            this.registerForm.e3DInviterUserId = this.e3dinviterId
            // 选择类型 aveva员工验证
            // var reg = /^([a-zA-Z0-9_.-])+@aveva.com$/;
            var reg = /^\w+([-+.]\w+)*@aveva.com$/;
            var flag = reg.test(this.registerForm.email)
            if(this.registerForm.partnerType == '10852' && !flag){
              this.$toast('请重新选择类型');
              this.loading = false
              return false
            }
            
            const registerForm = JSON.parse(JSON.stringify(this.registerForm))  
            if (!registerForm.companyId) {
              registerForm.companyId = "00000000-0000-0000-0000-000000000000";
            }else{
              delete registerForm.positionId
            }
            if(registerForm.partnerType == '10482'){
              delete registerForm.positionId
            }
            registerForm.partnerType = registerForm.partnerType *1;

            // * 同步注册剑盟成员
            if(this.channel !== -1 && this.channel) {
              this.synchronization ? registerForm.type = 0 : registerForm.type = 1
            } else {
              registerForm.type = -1
            }
            setRegister(registerForm).then((res) => {
              if (res.status == 1) {
                this.$toast.success({ message: "注册成功！", duration: 1000 });
                setTimeout(() => {
                  if(res.data) {
                    this.$store.commit("$_setStorage", res.data.token)
                    Login_GetInfoDetails(this.$route)
                  } else {
                    this.$router.push('/Login?redirectUrl=' + this.$route.query.redirectUrl)
                  }
                }, 500)
              } else{
                this.loading = false;
                this.$toast(res.message);
              }
            });
          } else {
            this.$alert("请先阅读并勾选论坛协议！", "提示", {
              confirmButtonClass: "el-button--mini",
              callback: (action) => {},
            });
            return false;
          }
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    // !点击获取验证码
    getPhoneCode() {
      const phone = this.registerForm.phone;
      const email = this.registerForm.email;
      if(this.registerForm.way=='phone'){
        if (!phone) {
          this.$toast({ message: "请先输入您的手机号！" });
          return false;
        // } else if (!validator(phone,1)) {
        //   this.$toast({ message: "请输入正确的手机号！" });
        //   return false;
        } else {
          debounce( () => {
            getCode({ phone: phone, type: 0, way: 'phone' }).then((res) => {
              if (res.status == 1) {
                this.$toast("发送成功！");
                const TIME_COUNT = 60;
                if (!this.timer) {
                  this.count = TIME_COUNT;
                  this.codeShow = false;
                  this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                      this.count--;
                    } else {
                      this.codeShow = true;
                      clearInterval(this.timer);
                      this.timer = null;
                    }
                  }, 1000);
                }
              } else {
                this.$toast(res.message);
              }
            });
          },1000)
        }
      }else if(this.registerForm.way=='email'){
        if (!email) {
          this.$toast({ message: "请先输入您的邮箱地址！" });
          return false;
        } else if (!validator(email,2)) {
          this.$toast({ message: "请输入正确的邮箱地址！" });
          return false;
        } else {
          debounce( () => {
            getCode({ email: email, type: 0, way: 'email' }).then((res) => {
              if (res.status == 1) {
                this.$toast("发送成功！");
                const TIME_COUNT = 60;
                if (!this.timer) {
                  this.count = TIME_COUNT;
                  this.codeShow = false;
                  this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                      this.count--;
                    } else {
                      this.codeShow = true;
                      clearInterval(this.timer);
                      this.timer = null;
                    }
                  }, 1000);
                }
              } else {
                this.$toast(res.message);
              }
            });
          },1000)
        }
      }
      
      // 正则验证格式
      function validator(value) {
        var reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        return RegExp(reg).test(value);
      }
    },
    // !查看密码
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = 'text'
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    // !去登录
    goLogin() {
      if(this.$route.query.from) {
        this.$router.push('/Login?redirectUrl=' + this.$route.query.redirectUrl + '&from=' + this.$route.query.from)
      } else if (this.channel === 1) {
        this.$router.push('/Login?redirectUrl=' + this.$route.query.redirectUrl)
      } else {
        this.$router.push('/Login')
      }
    },
    // !去首页
    goHome() {
      if(this.$route.query.from) {
        this.$router.push('/Home/Live')
      } else if (this.channel === 1) {
        this.$router.push('/Home/Live')
      } else {
        this.$router.push('/')
      }
    },
  },
  components: {
    loginHeader,
    LoginAlert,
    Footers
  },
  computed: {
    ...mapState(['clientWidth','Module', 'channel','inviterId','e3dinviterId'])
  },
};
</script>

<style lang="scss">
$mobil_base: 750;
@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}
.register{
  .register-body { padding: 15px 0; min-height: calc(100vh - 83px) }
  .register-content { width: 100%; max-width: 1200px; margin: 0 auto; overflow: hidden; }
  .register-logo img { width: 589px }
  .register-detail { box-shadow: 0 0 10px #ccc }
  .passwordInput{position: relative;}
  .passwordInput .show-pwd{position: absolute; right: 10px;top: 0;height: 100%;display: flex;align-items: center;}
  .privacy-text,
  .subscription,
  .privacy-text label {font-size: 12px;margin-bottom: 10px;}
  .el-checkbox__label {white-space: normal!important;}
  .el-checkbox__input.is-checked+.el-checkbox__label{color: #606266;}
  .privacy-text .el-checkbox__inner,.subscription .el-checkbox__inner{margin-top: 3px;}
  .agrees {color: #606266;}
  .el-cascader{ width: 100%; }
}
  .el-cascader-menu:nth-child(even) {
   .el-checkbox {
      width: 100%;
      height: 100%;
      z-index: 10;
      position: absolute;
    }
    .el-cascader-node__label{
      margin-left: 10px;
    }
    .el-scrollbar__bar {
      opacity: 0 !important;
    }
  }
  .el-scrollbar {
    .el-scrollbar__bar {
      opacity: 1 !important;
    }
  }
  

@media (min-width: 750px) {
  .register {
    background: #f7f7f7;
      .register-detail {
        padding: 30px 0;
        width: 550px;
        background: #fff;
        h1 {
          font-size: 24px;
          font-weight: bold;
          color: #363d42;
          line-height: 46px;
          text-align: center;
          margin-bottom: 15px;
        }
        .registerForm {
          width: 475px;
          margin: 0 auto;
          .way_input{ margin: 0 0 10px;
            .notice{ font-size: 13px; color: red; line-height: 20px; }
          }
          .form-item {
            &.code_input {
              /deep/ .el-form-item__content {
                display: flex;
                justify-content: space-between;
                .el-input {
                  width: 150px;
                }
                .el-button {
                  width: 120px;
                  &:hover {
                    background: #fff;
                    color: #5f1593;
                  }
                }
              }
            }
          }
        }
      }
  }
}
@media (max-width: 750px) {
  .my-select{
    max-width: vw(600);
  }
  .el-cascader{
    line-height: vw(60);
  }
  .el-cascader .el-input__icon{
    line-height: vw(60);
  }
  .el-cascader .el-input__inner{
    height: vw(60) !important;
  }
  .my-select .el-select-dropdown__item {
    width: vw(500);
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .register {
    min-height: 100vh; background: #f7f7f7;
    .register-body { padding: 0 }
    .register-logo { margin: vw(60) 0; text-align: center }
    .register-logo img { width: vw(321) }
    .register-detail { padding: vw(40); background: #fff; margin: vw(40); 
      .registerForm{
        .way_input{ margin: 0 0 8px;
          .notice{ font-size: 12px; color: red; line-height: 20px; }
        }
      }
    }
    .register-detail h1 { font-size: vw(30); font-weight: bold; color: #363d42; line-height: vw(46); text-align: center; margin-bottom: vw(15);}
  }
}
</style>
